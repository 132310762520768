globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"b58b529abcac28fd369f60db5a36ed4da1930b6d"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs'

Sentry.init({
  debug: false,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      networkDetailAllowUrls: [window.location.origin, 'https://api.coingecko.com/api/v3/simple/price'],
    }),
  ],
  replaysOnErrorSampleRate: 0.25,
  sampleRate: 0.5,
})
